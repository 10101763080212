<template>
  <div v-if="!loading" class="product-detail">
    <div class="d-flex justify-content-start align-items-center mb-3">
      <!-- <div>
        <a-icon @click.prevent="() => $router.go(-1)" type="arrow-left" style="font-size: 20px; color: #000000; cursor: pointer" />
      </div> -->
      <h3 class="m-0"> Detail Coin </h3>
    </div>

    <div class="card mt-3" style="padding: 2rem" v-if="product">
      <a-row type="flex" :gutter="[32,8]">
        <a-col :xs="{ span: 24, order: 1 }" :sm="{ span: 24, order: 1 }" :md="{ span: 12, order: 1 }">
          <a-row>
            <a-col
              :span="24"
              class="d-flex justify-content-center align-items-center"
              v-if="!!showImage || (product && product.all_images)"
            >
              <inner-image-zoom
                :src="`${!!showImage ? showImage.url : product.all_images[0].url}`"
                class="img-zoom"
              />
            </a-col>

            <a-col
              :span="24"
              class="d-flex justify-content-center align-items-center flex-wrap mt-3"
            >
              <img
                v-for="(image, index) in showListImage"
                :key="index"
                @click="setShowImage(image)"
                class="img-thumbnail mb-2 mx-2"
                :src="`${image.url}`"
                style="width: 90px; height: 90px; cursor: pointer; object-fit: contain;"
              />
            </a-col>

            <a-col
              :span="24"
              class="d-flex justify-content-center align-items-center mt-3"
              v-if="haveButtonAllImages"
            >
              <div class="button-expand-image text-center" @click.prevent="handleButtonAllImages">
                {{ isExpandImage ? $t('lessButtonCaption') : $t('expandButtonCaption') }}
              </div>
            </a-col>
          </a-row>
        </a-col>

        <a-col
          :xs="{ span: 24, order: 2 }" :sm="{ span: 24, order: 2 }" :md="{ span: 12, order: 2 }"
          class=""
        >
          <a-row>
            <a-col :span="24" class="mt-5 mb-3 mt-md-0 title">
              {{ product.title }}
            </a-col>

            <!-- <a-col :span="24" class="mt-2 mb-3 price" v-if="unitSelected">
              {{ unitSelected.selling_price | currency }}
            </a-col> -->

            <a-divider />

            <a-row>
              <a-col :span="24" class="mb-2" v-if="product && product.catalogs">
                  <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center">
                    <div> SKU </div>
                    <div> {{ selectedVariant.sku }} </div>
                  </a-col>
              </a-col>
            </a-row>
              <a-row>
                <a-col :xs="10" :sm="10" :md="9" style="width :-webkit-fill-available" class="d-flex align-items-center py-2">
                  <img src="https://ik.imagekit.io/fepca/220309_Desain_Icon_Kino_Siap_-_Loyalty_Point_1_d0yHZ4-ch.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1653948298528" height="29"/>
                  <div class="ml-2 price d-flex" v-if="unitSelected">
                    {{ toCurrency(unitSelected.selling_price) }}
                  </div>
                </a-col>
              </a-row>

            <a-divider class="mt-3" />
            <!-- hide promo sementara -->
            <!-- <a-col :span="24" class="mt-2 mb-3" v-if="product && product.catalogs">
              <a-row>
                <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center">
                  <div> Deskripsi Promo : </div>
                  <div class="desc-promo"> All Variant </div>
                </a-col>
              </a-row>
            </a-col>
            <a-divider /> -->
            <a-col :span="24" class="mt-2 mb-0" v-if="product && product.description">
              <div
                class="mb-3"
                style="
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  letter-spacing: 0.05em;
                  color: #000000;
                "
              >
                {{ $t('purchase.catalogue.product_description') }}
              </div>

              <div class="my-1 text-desc"> {{ product.description.short_description }} </div>
              <div class="text-desc" v-html="product.description.long_description" />
            </a-col>
            <a-divider class="m-0 mb-3"/>
            <div class="container-disclaimer-coin px-3 py-1 mb-4 rounded-lg">Catatan : Anda dapat menggunakan Poin untuk pembelian barang apapun <i>1 Poin = 1 Rupiah</i></div>

            <a-col :span="24" class="mt-2 mb-3 variant" v-if="variants && variants.length > 1">
              <div class="variant__title"> {{ $t('purchase.catalogue.select_variant') }} </div>

              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div
                  v-for="(item, index) in variants"
                  :key="index"
                  class="variant__option my-3 mr-3 d-flex justify-content-center align-items-center"
                  @click.prevent="() => handleChangeVariant(item.catalog_id)"
                  :class="{ active: selectedVariant && selectedVariant.id === item.catalog_id}"
                >
                  <div>
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </a-col>

            <a-divider v-if="variants && variants.length > 1" />
            <a-col :md="15" class="d-flex justify-content-end" style="width :-webkit-fill-available">
              <a-button @click="backCatalogue" class="mr-3" type="primary" ghost >Kembali</a-button>
              <a-button @click="visible = true" type="primary" >+ Keranjang</a-button>
            </a-col>

          </a-row>
        </a-col>
      </a-row>

        <b-modal id="modal-response" hide-footer header-class="border-0" v-model="visible" centered>
          <template>
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center" style="width : -webkit-fill-available">
              <span style="color : rgb(38, 92, 155); font-weight : bold;">{{ product.title }}</span>
              </a-col>
            </a-row>

            <!-- <a-divider />

            <a-col :span="24" class="mt-2 mb-3" v-if="product && product.catalogs">
              <a-row>
                <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center">
                  <div> Varian </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
                  <a-button type="primary" ghost>Jambu Biji</a-button>
                </a-col>
              </a-row>
            </a-col> -->

            <a-divider />

           <a-row>
                <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
                  <span danger >Unit</span>
                </a-col>
                 <a-col :md="12" class="unit-cart d-flex justify-content-end">
                 <div class="select-box" style="cursor: pointer">
                  <a-dropdown :trigger="['click']">
                      <div class="d-flex justify-content-end align-items-center">
                        <div style="align-self: self-end;"> {{ unitSelected ? `${unitSelected.uom}` : $t('purchase.catalogue.select_unit') }}  </div>
                        <div class="icon-unit"> <a-icon type="down" /> </div>
                      </div>
                    <a-menu slot="overlay" @click="handleSelectUom">
                      <a-menu-item v-for="(item) in unitList" :key="item.uom" class="row">
                        <div class="col-4"> {{ item.uom }} </div>
                        <div class="col-8"> {{ item.selling_price | currency }} </div>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                 </div>
                </a-col>
              </a-row>

            <a-divider />

            <a-row>
                <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
                  <span danger >Jumlah</span>
                </a-col>
                 <a-col :md="12" class="d-flex justify-content-end jumlah-cart">
                  <div @click.prevent="updateQty('min')" style="cursor: pointer"> - </div>
                    <div>
                      <a-input
                        class="centered-input"
                        :value="qty"
                        @keypress="validateQty($event)"
                        @input="updateQty"
                        @blur="handleNullQty"
                      />
                    </div>
                  <div class="mr-3" @click.prevent="updateQty('add')" style="cursor: pointer"> + </div>
                </a-col>
              </a-row>

         <div class="pb-3 mb-5">
           <a-col :span="24" class="mt-2" :style="{cursor: unitSelected && !showDisclaimerQty ? 'pointer' : ''}" @click.prevent="handleAddToCart">
          <div
            class="button-add d-flex justify-content-center pt-2 rounded-lg mt-3"
            :style="{ backgroundColor: !unitSelected || showDisclaimerQty ? '#E5E5E5 !important' : '#265C9B !important', color: 'white',height: '40px'}"
            v-if="!loadingPurchase"
          >
            + {{ $t('purchase.catalogue.cart') }}
          </div>

          <div
            class="button-add d-flex justify-content-center pt-2"
            :style="{ backgroundColor: !unitSelected ? '#E5E5E5 !important' : '#265C9B !important' }"
            v-if="loadingPurchase"
          >
            <a-icon type="loading" style="color: #FFFFFF" />
          </div>
        </a-col>
         </div>
      </template>
    </b-modal>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InnerImageZoom from 'vue-inner-image-zoom'

export default {
  components: {
    InnerImageZoom,
  },
  data: function () {
    return {
      showImage: null,
      title: 'Product Detail',
      price: [],
      qty: 1,
      unitSelected: null,
      selectedVariant: {},
      variants: [],
      unitList: [],
      haveButtonAllImages: false,
      showListImage: [],
      isExpandImage: false,
      showDisclaimerQty: false,
      visible: false,
      uom: '',
    }
  },
  metaInfo () {
    return {
      title: this.title,
    }
  },
  computed: {
    ...mapState('product', {
      product: state => state.productDetail,
      colorOptions: state => state.colorOptions,
      sizeOptions: state => state.sizeOptions,
      flavorOptions: state => state.flavorOptions,
      defaultOptions: state => state.defaultOptions,
      loading: state => state.loading,
    }),
    loadingPurchase () {
      return this.$store.state.purchase.loading
    },
  },
  mounted () {
    this.fetchProductDetail()
  },
  methods: {
    backCatalogue() {
      this.$router.push({ path: '/purchase/catalogue' })
    },

    async fetchProductDetail () {
      this.$store.dispatch('product/GETPRODUCTDETAIL', {
        product_id: this.$route.params.id,
        channel_id: this.$store.state.app.redeem_channel_id,
        warehouse_id: this.$route.query.warehouse_id,
      })
        .then(() => {
          const selected = []

          if (Array.isArray(this.product.catalogs)) {
            this.product.catalogs.forEach(item => {
              if (item.option) {
                const title = []
                for (const property in item.option) {
                  title.push(item.option[property])
                }
                selected.push({ title: title.join(' '), catalog_id: item.id })
              } else {
                const product_title = this.product.title.split(' ')
                const catalog_title = item.title.split(' ')

                // Hanya logic untuk case tidak ada option di catalogs
                // karena setiap catalog_title itu adalah product_title + variantnya.
                // maka dibuatlah logic seperti ini.

                const data = {
                  title: [],
                  catalog_id: item.id,
                }

                catalog_title.forEach((item, index) => {
                  if (item !== product_title[index]) {
                    data.title.push(item)
                  }
                })

                selected.push({ ...data, title: data.title.length ? data.title.join(' ') : item.title })
              }
            })
          }

          this.variants = selected
          this.selectedVariant = this.product.catalogs[0]

          return this.$store.dispatch('price/GETPRICEREDEEMPRODUCT', {
            id: [this.$route.params.id],
            channel_id: this.$store.state.app.redeem_channel_id,
            effective_date: new Date(),
          })
        })
        .then(({ data }) => {
          this.price = data
          this.createUnitList(this.$route.params.id, this.selectedVariant && this.selectedVariant.id)
        })
    },
    createUnitList (product_id, catalog_id) {
      if (!product_id || !catalog_id) {
        return
      }

      const product = this.price.find(item => item.product_id === product_id && item.catalogs.find(value => value.id === catalog_id))

      const price = product?.catalogs?.find(item => item.id === catalog_id)

      this.unitList = price ? price.price_list : []
      this.unitSelected = price?.price_list?.length > 0 ? price?.price_list[0] : null
    },
    setShowImage (value) {
      this.showImage = value
    },
    updateQty (param) {
      if (param === 'min' && this.qty > 1) {
        this.qty = this.qty - 1
      } else if (param === 'add' && String(this.qty + 1).length <= 4) {
        this.qty = this.qty + 1
      } else {
        if (String(param.target.value).length <= 4) {
          if (param.target.value === '' || param.target.value === '0') {
            this.showDisclaimerQty = true
            this.qty = ''
          } else {
            this.showDisclaimerQty = false
            this.qty = parseInt(param.target.value)
          }
        }
        this.$forceUpdate()
      }
    },
    handleNullQty (param) {
      if (param.target.value === '' || param.target.value === '0') {
        this.qty = 1
        this.showDisclaimerQty = false
      }
    },
    validateQty (event) {
      const regex = new RegExp(/^[\d]*$/g)
      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    handleSelectUom ({ key }) {
      this.unitSelected = this.unitList.find(item => item.uom === key) || null
      this.uom = this.unitSelected.uom
    },
    handleAddToCart () {
      if (!this.unitSelected) {
        return this.$notification.warning({
          message: this.$t('purchase.cart.notif_select_unit'),
        })
      }

      const data = {
        cart_id: this.$store.state.purchase.cartId,
        item_id: this.selectedVariant.id,
        product_id: this.$route.params.id,
        catalog_sku: this.selectedVariant.sku,
        catalog_title: this.selectedVariant.title,
        // showimg_url: this.selectedVariant.showimg_url,
        showimg_url: this.product.showimg_url,
        qty: this.qty,
        uom: this.unitSelected && this.unitSelected.uom,
        buyer_id: this.$store.state.user.user_data.buyer_id,
        area_id: this.$store.state.user.user_data.area_id,
        price_id: this.unitSelected && this.unitSelected.id,
        item_price: this.unitSelected && this.unitSelected.selling_price,
        currency: this.unitSelected && this.unitSelected.currency,
        warehouse_id: this.$route.query.warehouse_id,
      }

      this.$store.dispatch('purchase/ADDTOCART', {
        ...data,
        channel_id: this.$store.state.app.redeem_channel_id,
      })
        .then(({ data }) => {
          this.$notification.success({
            message: this.$t('purchase.cart.success'),
            placement: 'bottomRight',
          })
          this.visible = false
        })
        .catch((err) => {
          const { data } = err.response
          this.$notification.error({
            message: this.$t('purchase.cart.failed'),
            placement: 'bottomRight',
            description: data && data.message ? data.message : 'Internal Server Error',
          })
          this.visible = false
        })
    },
    handleChangeVariant (id) {
      this.selectedVariant = this.product.catalogs.find(item => item.id === id) || {}
    },
    handleButtonAllImages () {
      if (!this.isExpandImage) {
        this.showListImage = this.product.all_images.map(item => item)
        this.isExpandImage = !this.isExpandImage
        // eslint-disable-next-line
        return
      }

      if (this.isExpandImage) {
        const tmp = []
        for (let i = 0; i < 4; i++) {
          tmp.push(this.product.all_images[i])
        }

        this.showListImage = tmp
        this.isExpandImage = !this.isExpandImage
        // eslint-disable-next-line
        return
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (Array.isArray(value.all_images) && value.all_images.length > 4) {
          this.haveButtonAllImages = true
          const tmp = []
          for (let i = 0; i < 4; i++) {
            tmp.push(value.all_images[i])
          }

          this.showListImage = tmp
        } else {
          this.haveButtonAllImages = false
          this.showListImage = value.all_images.map(item => item)
        }
      },
    },
  },
}
</script>

<style lang="scss">
.product-detail {
  .catalog-icon {
    background: #fff !important;
    padding: 7px 12px;
    border-radius: 50%;
  }

  .button-uom-dis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 9px;
    width: 50px;
    height: 35px;
    background: #F4F4F4;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #767676;
  }

  .button-uom-select {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 9px;
    width: 50px;
    height: 35px;
    background: #FFF1E5;
    border: 1px solid #F5831F;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  .price {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #F5831F;
  }

  .select-box {
    width: 100%;
    height: 33px;
    left: 40px;
    top: 80px;
    padding: .5rem;
    border: 0.5px solid #585858;
    box-sizing: border-box;
    border-radius: 2px !important;
  }

  .button-add {
    width: 100%;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: #265C9B;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .variant {
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &__option {
      width: auto;
      height: 27px;
      background: #F4F4F4;
      border-radius: 8px;
      padding: .5rem;
      cursor: pointer;
    }

    .active {
      background: #265C9B !important;
      color: #FFF;
    }
  }

  .button-expand-image {
    border: 1px solid #265C9B;
    color: #265C9B;
    padding: 5px;
    width: 50%;
    border-radius: 8px;
    cursor: pointer;
  }

  .button-expand-image:hover {
    background: #265C9B;
    color: #FFFFFF;
  }
}

.centered-input {
  text-align: center;
  border: 0px !important;
  height: 30px !important;
}

.required-qty-details {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  margin-top: 2px;
}

.product-promo {
  position: absolute;
  background: #FF0000;
  color: white;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 20px;
  top: 12px;
  right: 12px;
  z-index: 10;
}
.desc-promo {
  color: red;
  font-weight: bold;
}
.unit-cart {
  height :35px;
  margin-left:40px;
  border :groove;
  border-radius:10px;
}
.icon-unit {
  padding-left:140px;
  padding-right:10px;
  padding-top:5px;
}
.jumlah-cart {
  margin-left:40px;
  border :groove;
  border-radius:10px;
}
.img-zoom {
  div {
    display: flex;
    justify-content: center;
    width: 500px;
    height: 400px;

    img {
      max-height: 400px;
      object-fit: contain !important;
    }
  }
}
.container-disclaimer-coin {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  background-color: #F4F4F4;
}
.text-desc {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: justified;
  color: #000000;
}
</style>
